import {IUrlParameters} from '../../components/lists/UrlFilteredList'
import {emptyUrlParameters} from '../../utils/constants'

export const emptyInvoiceUrlParameters: IUrlParameters = {
  ...emptyUrlParameters,
  url: '/account/settings/invoices/',
}

export type InvoiceType = {
  id: number
  customer: number
  amount: string
  issued_date: string
  due_date: string
  status: 'pending' | 'paid' | 'canceled'
}

export interface IInvoice {
  count: number
  next: string | null
  previous: string | null
  results: InvoiceType[]
}

export interface IInvoiceState {
  invoices: IInvoice
  isLoading: boolean
}

export interface IInvoicesStorage {
  isLoading: boolean
  items: InvoiceType[]
  selected: InvoiceType | null
  count: number
  next: string | null
  previous: string | null
  invoiceDocument: string | null
  isCreatingInvoicePayment: boolean
  isRenewing: boolean
  filters: IUrlParameters
}

export interface IPaymentRequestParams {
  amount: number
}

export const emptyState: IInvoicesStorage = {
  isLoading: false,
  items: [],
  selected: null,
  count: 0,
  next: null,
  previous: null,
  invoiceDocument: null,
  isCreatingInvoicePayment: false,
  isRenewing: false,
  filters: emptyInvoiceUrlParameters,
}

export enum InvoicesActionTypes {
  FetchInvoices = 'FetchInvoices',
  CreatePaymentDocument = 'CreatePaymentDocument',
}
