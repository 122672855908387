import axios from 'axios'
import {TRegisterYMTokenRequest, TGetCaptchaRequest} from './types'

const API_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENDPOINT_PREFIX}`
export const USERS_URL = `${API_URL}v2/users/`
export const TOKENS_URL = `${API_URL}v2/tokens/`

// #region
//https://api-dev.shop-delivery.ru/int_api/v2/users/get_captcha/

export const getCaptcha = (data: TGetCaptchaRequest) => {
  return axios.post(`${USERS_URL}get_captcha/`, {phone_number: data.phone}, {responseType: 'blob'})
}

// #endregion

// #region
//https://api-dev.shop-delivery.ru/int_api/v2/users/post_captcha/

export const postCaptcha = (captcha: string) => {
  return axios.post(`${USERS_URL}post_captcha/`, {captcha_code: captcha})
}

// #endregion

// #region
//https://api-dev.shop-delivery.ru/int_api/v2/users/post_phone_code/

export const postCodePhone = (code: string) => {
  return axios.post(`${USERS_URL}post_phone_code/`, {phone_code: code})
}

// #endregion

// #region
//https://api-dev.shop-delivery.ru/int_api/v2/users/post_phone_code/

export const getYMTokenLink = () => {
  return axios.get(`${TOKENS_URL}get_ym_token_link/`)
}

// #endregion
// #region
//https://api-dev.shop-delivery.ru/int_api/v2/users/post_phone_code/

export const registerYMToken = ({id, validation_code}: TRegisterYMTokenRequest) => {
  return axios.get(`${TOKENS_URL}${id}/registrate_ym_token/?validation_code=${validation_code}`)
}

// #endregion
