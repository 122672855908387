import ReactDOM from 'react-dom'
// Redux
// https://github.com/rt2zz/redux-persist
import {PersistGate} from 'redux-persist/integration/react'
import {Provider} from 'react-redux'
import * as _redux from './setup'
import store, {persistor} from './setup/redux/Store'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
import {AbilityProvider} from './app/context/AbilityProvider'
import ability from './setup/ability'

// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
import './_custom/custom.scss'

import {AppRoutes} from './app/routing/AppRoutes'
import {intl} from './app/modules/goods/item/validation'
import React from 'react'

// Sentry.init({
//   dsn: "https://148edf8ef64d4100b23999d97663d960@sentry.vm.somebox.ru/12",
//   integrations: [new Integrations.BrowserTracing()],
//   //debug: true,
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

// // actual 2023
// Sentry.init({
//   dsn: "https://e0fcfde74e304809beb72d787a4e3a12@sentry.somebox.ru/6",
//   integrations: [new Sentry.BrowserTracing()],
//   tracesSampleRate: 1.0,
// });

// actual november 2023
// Sentry.init({
//   dsn: "https://0a8e160b09986e6f3e57cb3d0213f210@sentry.somebox.ru/5",
//   integrations: [new Sentry.BrowserTracing()],
//   tracesSampleRate: 1.0,
// });

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store)

Chart.register(...registerables)

const queryClient = new QueryClient()

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <MetronicI18nProvider>
      <Provider store={store}>
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        <PersistGate
          persistor={persistor}
          loading={
            <div>{intl.formatMessage({id: 'STATUS.LOADING'})}</div>
            // <Loader1 />
          }
        >
          <AbilityProvider ability={ability}>
            <AppRoutes />
          </AbilityProvider>
        </PersistGate>
      </Provider>
    </MetronicI18nProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
  document.getElementById('root')
)
