import {FC, useEffect, useState} from 'react'
import {Button, ButtonGroup, Col, Dropdown, DropdownButton, Row} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate, useSearchParams} from 'react-router-dom'
import ReactSelect, {GroupBase, StylesConfig} from 'react-select'
import {StorageType} from '../../../../setup'
import {ISelectOption} from '../../../components/common/select/AsyncSelectInput'
import Filter from '../../../components/lists/UrlFilter'
import {IUrlParameters} from '../../../components/lists/UrlFilteredList'
import {emptyUrlParameters} from '../../../utils/constants'
import DateRangePickerBootstrap from '../../../components/select/date-picker/DateRangePicker'
import ModalInfo from '../../../components/vision/info/ModalInfo'
import {IAccountState} from '../../../store/accounts/types'
import {ICustomerCompanyState} from '../../../store/customerCompanys/types'
import {IMarketplaceState} from '../../../store/marketplaces/types'
import {
  getAsyncOrdersWithFilterCreator,
  setAsyncOrderStatusesCreator,
  setOrdersRequestParams,
} from '../../../store/orders/actions'
import {getOrderStickersOnServer} from '../../../store/orders/api'
import {DEFAULT_ORDERS_GET_LIMIT, DEFAULT_ORDERS_PAGE} from '../../../store/orders/constants'
import {IOrderItem, IOrderStorage} from '../../../store/orders/types'
import {IOrderStatus, IOrderStatusState} from '../../../store/orderStatuses/types'
import {createLink, errorUtils, messageUtils} from '../../../utils/dataUtils'
import OrdersList from './OrdersList'
import 'moment/locale/ru'
import moment from 'moment'
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput'
import {intl} from '../../goods/item/validation'
import PushMessager from '../../../store/system/pushMessager'

import css from './OrdersListWrapper.module.css'
import {useAppLayout} from '../../../../_metronic/layout/core/AppLayoutProvider'
import OrdersMenu from '../OrdersMenu'
import ModalWindowExtended from '../../../components/modals/cathegory-select-modal/ModalWindowExtended'
import OrdersItemWrapper from '../item/OrdersItemWrapper'

moment.locale(
  intl.formatMessage({id: 'COMPONENTS.ORDERS.LIST.FILTERS.DATERANGE.LOCALIZATION-LANG'})
)
const defaultCreateDate = {startDate: moment().add(-2, 'days'), endDate: moment().add(-1, 'days')}
const defaultShipDate = {startDate: moment().add(-1, 'days'), endDate: moment()}

export const requestSticker = async (items: number[]): Promise<HTMLAnchorElement | null> => {
  try {
    if (items?.length) {
      let response = await getOrderStickersOnServer({orderIds: items})
      let dta: any = response.data
      // Creating new object of PDF file
      const fileURL = window.URL.createObjectURL(dta)
      // Setting various property values
      let alink = document.createElement('a')
      alink.href = fileURL
      alink.target = '_blank'

      //alink.click(); // use it to open file in another tab
      return alink
    } else return null
  } catch (error) {
    const err = errorUtils.getErrorFromObject(error)
    err
      ? PushMessager.addError(
          intl.formatMessage({id: 'STATUS.ERROR1'}),
          intl.formatMessage({id: 'COMPONENTS.MPDOCS.LIST.ERROR.STICKERFAILED'}),
          err
        )
      : PushMessager.addError(
          intl.formatMessage({id: 'STATUS.ERROR1'}),
          intl.formatMessage({id: 'COMPONENTS.MPDOCS.LIST.ERROR.STICKERFAILED'})
        )
    return null
  }
}

interface IDateRange {
  startDate: moment.Moment
  endDate: moment.Moment
}

export interface IChangeUrlParameter {
  selected: ISelectOption | ISelectOption[]
  optionName: string
  optionToReset?: string
}

// styles for designed html-select
export const ReactSelectSimpleStyles: StylesConfig<any, boolean, GroupBase<any>> | undefined = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: '#F5F8FA',
    border: 'none',
  }),
  input: (provided, state) => ({
    ...provided,
    border: 'none',
    color: 'black',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    border: 'none',
    color: 'black',
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  container: (provided, state) => ({
    ...provided,
    border: 'none',
  }),
  dropdownIndicator: (provided, state) => ({...provided, color: 'black'}),
}

// from string to IDateRange
const convertStringToIDateRange = (
  after: string,
  before: string,
  defaultDate: IDateRange
): IDateRange => {
  let res: IDateRange | null

  try {
    res = {
      startDate: moment(after).isValid() ? moment(after) : defaultDate.startDate,
      endDate: moment(before).isValid() ? moment(before) : defaultDate.endDate,
    }
  } catch {
    res = defaultDate
  }

  return res
}

// range 1 equals range 2
const dateRangeIsEqual = (range1: IDateRange, range2: IDateRange): boolean => {
  return range1.startDate == range2.startDate && range1.endDate == range2.endDate
}

interface IOrdersListWrapperProps {
  itemId?: number
}

const OrdersListWrapper: FC<IOrdersListWrapperProps> = (props) => {
  // filter constants
  const marketplaceParameter = 'marketplace'
  const accountParameter = 'account'
  const companyParameter = 'company'
  const statusParameter = 'status'
  const created_before_Parameter = 'created_before'
  const created_after_Parameter = 'created_after'
  const ship_before_Parameter = 'ship_at_before'
  const ship_after_Parameter = 'ship_at_after'
  const back_from_item = 'back'

  const dispatch = useDispatch()
  const intl = useIntl()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const appLayout = useAppLayout()

  // get data from storage
  const orderState: IOrderStorage = useSelector((s: StorageType) => s.orders)
  const orderStatusesState: IOrderStatusState = useSelector((s: StorageType) => s.orderStatuses)
  const marketplaceState: IMarketplaceState = useSelector((s: StorageType) => s.marketplaces)
  const accountState: IAccountState = useSelector((s: StorageType) => s.accounts)
  const companyState: ICustomerCompanyState = useSelector((s: StorageType) => s.customerCompanys)

  const [orderStateItems, setOrderStateItems] = useState<IOrderItem[]>([])
  const [showModal, setShowModal] = useState<boolean>(false)

  // get main filter parameters from url
  const page = +(searchParams.get('page') ?? DEFAULT_ORDERS_PAGE)
  const perPage = +(searchParams.get('ipp') ?? DEFAULT_ORDERS_GET_LIMIT)
  const filter = searchParams.get('search') ?? ''
  const order = searchParams.get('order') ?? 'id'
  const [quickSelectedItem, setQuickSelectedItem] = useState<string>('')

  // get additional filter parameters from url
  const marketplaceUrlParam = searchParams.get(marketplaceParameter) ?? ''
  const accountUrlParam = searchParams.get(accountParameter) ?? ''
  const companyUrlParam = searchParams.get(companyParameter) ?? ''
  const statusesUrlParam = searchParams.get(statusParameter) ?? ''
  const createdAfterUrlParam = searchParams.get(created_after_Parameter) ?? ''
  const createdBeforeUrlParam = searchParams.get(created_before_Parameter) ?? ''
  const shipAfterUrlParam = searchParams.get(ship_after_Parameter) ?? ''
  const shipBeforeUrlParam = searchParams.get(ship_before_Parameter) ?? ''
  const backFromItem = searchParams.get(back_from_item) ?? ''

  // init filters variables
  const selectedMarketplace = marketplaceState?.items?.find(
    (mp) => mp.id.toString() == marketplaceUrlParam
  )
  const selectedCompany = companyState?.items?.find((mp) => mp.id.toString() == companyUrlParam)
  const selectedAccount = accountState?.items?.find((mp) => mp.id.toString() == accountUrlParam)
  const selectedStatuses = orderStatusesState?.items?.filter((mp) =>
    statusesUrlParam.split(',').includes(mp.code)
  )

  // prepare (init) variables for date range filters
  const [selectedCreateDates, setSelectedCreateDates] = useState<IDateRange>(
    convertStringToIDateRange(createdAfterUrlParam, createdBeforeUrlParam, defaultCreateDate)
  )
  const [selectedCreateDatesOn, setSelectedCreateDatesOn] = useState<boolean>(
    !dateRangeIsEqual(selectedCreateDates, defaultCreateDate) ?? false
  )
  const [selectedShipDates, setSelectedShipDates] = useState<IDateRange>(
    convertStringToIDateRange(shipAfterUrlParam, shipBeforeUrlParam, defaultShipDate)
  )
  const [selectedShipDatesOn, setSelectedShipDatesOn] = useState<boolean>(
    !dateRangeIsEqual(selectedShipDates, defaultShipDate) ?? false
  )

  const selectedItems: IOrderItem[] = orderStateItems.filter((i) => i.selected)

  const selectedIdsItems: number[] = selectedItems?.map((ii) => ii.id) ?? []

  const isPrintableItems: boolean =
    !!selectedItems.length && selectedItems.every((item) => item.printable)

  // parameters to filters, sorting and pagination
  const params: IUrlParameters = {
    filter,
    itemsPerPage: perPage,
    ordering: order,
    pageNumber: page,
    url: '/orders/orders/list/',
    additionalParameters: [
      {label: marketplaceParameter, value: marketplaceUrlParam},
      {label: accountParameter, value: accountUrlParam},
      {label: companyParameter, value: companyUrlParam},
      {label: statusParameter, value: statusesUrlParam},
      {label: created_before_Parameter, value: createdBeforeUrlParam},
      {label: created_after_Parameter, value: createdAfterUrlParam},
      {label: ship_before_Parameter, value: shipBeforeUrlParam},
      {label: ship_after_Parameter, value: shipAfterUrlParam},
    ],
  }

  // raises when any parameter in URL changes
  useEffect(() => {
    dispatch(setOrdersRequestParams(params))
  }, [searchParams])

  // raises when any filter parameter in storage (redux) changes
  useEffect(() => {
    if (!backFromItem.length || !orderState?.items?.length)
      // check it to cancel reload after back from item
      dispatch(getAsyncOrdersWithFilterCreator()) // call get orders with storage filters
  }, [
    orderState.filter?.itemsPerPage,
    orderState.filter?.pageNumber,
    orderState.filter?.ordering,
    orderState.filter?.filter,
    orderState.filter?.additionalParameters,
  ])

  useEffect(() => {
    setOrderStateItems(
      orderState.items.map((itm) => {
        const oldItm = orderStateItems.length && orderStateItems.find((osi) => osi.id == itm.id)
        return {
          ...itm,
          selected: oldItm ? oldItm.selected ?? false : false,
        }
      })
    )
  }, [orderState.items])

  // raises when load
  useEffect(() => {
    if (!backFromItem.length || !orderState?.items?.length)
      // check it to cancel reload after back from item
      dispatch(getAsyncOrdersWithFilterCreator()) // call get orders with storage filters

    appLayout.setToolbars(<OrdersMenu isActive='orders' />)

    return () => {
      // remove control back button
      appLayout.setToolbars(<></>)
    }
  }, [])

  // universal changer of additional parameter with reseting the related filter
  const changeSelectFilter = (paramData: IChangeUrlParameter | IChangeUrlParameter[]) => {
    const navigateObject = Array.isArray(paramData)
      ? createLink.paginationLink({
          ...params,
          pageNumber: 0, // reset page on 0 when change filter
          additionalParameters: [
            ...(params.additionalParameters?.map((ap) => {
              let founded = paramData.find((p) => p.optionName == ap.label)
              if (founded) {
                return {
                  label: founded.optionName,
                  value: founded.selected
                    ? Array.isArray(founded.selected)
                      ? founded.selected.map((s) => s.value).toString()
                      : founded.selected.value
                    : '',
                }
              } else {
                let foundedToReset = paramData.find(
                  (p) => p.optionToReset && p.optionToReset == ap.label
                )
                if (foundedToReset && foundedToReset.optionToReset)
                  return {
                    label: foundedToReset.optionToReset,
                    value: '',
                  }
                else return ap
              }
            }) ?? []),
          ],
        })
      : createLink.paginationLink({
          ...params,
          pageNumber: 0, // reset page on 0 when change filter
          additionalParameters: [
            ...(params.additionalParameters?.map((ap) =>
              ap.label == paramData.optionName
                ? {
                    label: paramData.optionName,
                    value: paramData.selected
                      ? Array.isArray(paramData.selected)
                        ? paramData.selected.map((s) => s.value).toString()
                        : paramData.selected.value
                      : '',
                  }
                : paramData.optionToReset && ap.label == paramData.optionToReset
                ? {
                    label: paramData.optionToReset,
                    value: '',
                  }
                : ap
            ) ?? []),
          ],
        })
    navigate(navigateObject.url)
  }

  // call to print sticker (barcode)
  const printBarcode = async () => {
    const items: number[] = selectedIdsItems
    setShowModal(true)
    const link = await requestSticker(items)
    setShowModal(false)
    link?.click()
  }

  // change status of order
  const changeStatuses = (newStatus: IOrderStatus) => {
    const items: number[] = selectedIdsItems
    items?.length &&
      messageUtils.question(
        intl.formatMessage({id: 'COMPONENTS.ORDERS.LIST.ALERT.CHANGESTATUS'}),
        dispatch,
        setAsyncOrderStatusesCreator({
          orders: items,
          status: newStatus,
        })
      )
  }

  // set selected rows
  const setSelectedItem = (item: IOrderItem, selected: boolean) => {
    setOrderStateItems(orderStateItems.map((osi) => (osi.id == item.id ? {...osi, selected} : osi)))
  }

  // select/unselect all rows
  const selectAll = (selected: boolean) => {
    setOrderStateItems(orderStateItems.map((osi) => ({...osi, selected})))
  }

  // changes mode (enabled/disabled) of date range filters
  const changeDateFilterVisibility = (
    setter: (val: boolean) => void,
    selectedDatesOn: boolean,
    parameters?: ISelectOption[],
    resetParameters?: string[]
  ) => {
    if (selectedDatesOn) {
      // выкл из url
      resetParameters &&
        changeSelectFilter(
          resetParameters.map((parameter) => ({
            selected: {value: '', label: parameter},
            optionName: parameter,
          }))
        )
    } else {
      parameters &&
        changeSelectFilter(
          parameters.map((parameter) => ({
            selected: {value: parameter.value, label: parameter.label},
            optionName: parameter.label,
          }))
        )
    }

    setter(!selectedDatesOn)
  }

  // set date range with controling disable
  const changeDateFilter = (
    picker: IDateRange,
    setter: (dateRange: IDateRange) => void,
    parameters: ISelectOption[]
  ) => {
    // change filter
    changeSelectFilter(
      parameters.map((parameter) => ({
        selected: {value: parameter.value, label: parameter.label},
        optionName: parameter.label,
      }))
    )

    // set current selected date range
    setter({
      startDate: picker.startDate,
      endDate: picker.endDate,
    })
  }

  return (
    <OrdersList
      count={orderState.count ?? 0}
      accounts={accountState?.items ?? []}
      marketplaces={marketplaceState?.items ?? []}
      companys={companyState?.items ?? []}
      isLoading={orderState.isListLoading}
      items={orderStateItems ?? []}
      itemUrl='/orders/orders/item/'
      filterParams={orderState.filter ?? emptyUrlParameters}
      title={intl.formatMessage({id: 'COMPONENTS.ORDERS.LIST.TITLE'})}
      // description={intl.formatMessage({ id: "COMPONENTS.ORDERS.LIST.SUBTITLE" })}
      setSelectedItem={setSelectedItem}
      setSelectedAllItems={selectAll}
      setQuickView={setQuickSelectedItem}
    >
      <div className='p-5'>
        <Row className='mb-3'>
          <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}>
            <ReactSelect
              isSearchable={false}
              className=' my-1'
              styles={ReactSelectSimpleStyles}
              isMulti={false}
              onChange={(selected: any) =>
                changeSelectFilter({
                  selected,
                  optionName: marketplaceParameter,
                  optionToReset: accountParameter,
                })
              }
              placeholder={intl.formatMessage({
                id: 'COMPONENTS.ORDERS.LIST.FILTERS.MARKETPLACE.PLACEHOLDER',
              })}
              loadingMessage={() =>
                intl.formatMessage({id: 'COMPONENTS.ORDERS.LIST.FILTERS.ALL.LOADING'})
              }
              noOptionsMessage={() =>
                intl.formatMessage({id: 'COMPONENTS.ORDERS.LIST.FILTERS.MARKETPLACE.NOVALUES'})
              }
              value={
                selectedMarketplace
                  ? {label: selectedMarketplace?.name, value: selectedMarketplace?.id}
                  : null
              }
              getOptionLabel={(w: any) => w.label}
              isClearable={true}
              options={marketplaceState?.items?.map((mp) => ({label: mp.name, value: mp.id})) ?? []}
            />
          </Col>
          <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}>
            <ReactSelect
              isSearchable={false}
              className=' my-1'
              isMulti={false}
              styles={ReactSelectSimpleStyles}
              onChange={(selected: any) =>
                changeSelectFilter({selected, optionName: accountParameter})
              }
              placeholder={intl.formatMessage({
                id: 'COMPONENTS.ORDERS.LIST.FILTERS.ACCOUNT.PLACEHOLDER',
              })}
              loadingMessage={() =>
                intl.formatMessage({id: 'COMPONENTS.ORDERS.LIST.FILTERS.ALL.LOADING'})
              }
              noOptionsMessage={() =>
                intl.formatMessage({id: 'COMPONENTS.ORDERS.LIST.FILTERS.ACCOUNT.NOVALUES'})
              }
              value={
                selectedAccount ? {label: selectedAccount?.name, value: selectedAccount?.id} : null
              }
              getOptionLabel={(w: any) => w.label}
              isClearable={true}
              options={
                accountState?.items
                  ?.filter((itm) =>
                    selectedMarketplace ? itm.marketplace == selectedMarketplace?.id : true
                  )
                  ?.map((mp) => ({label: mp.name, value: mp.id})) ?? []
              }
            />
          </Col>
          <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}>
            <ReactSelect
              isSearchable={false}
              className=' my-1'
              isMulti={false}
              styles={ReactSelectSimpleStyles}
              onChange={(selected: any) =>
                changeSelectFilter({selected, optionName: companyParameter})
              }
              placeholder={intl.formatMessage({
                id: 'COMPONENTS.ORDERS.LIST.FILTERS.CUSTOMER.PLACEHOLDER',
              })}
              loadingMessage={() =>
                intl.formatMessage({id: 'COMPONENTS.ORDERS.LIST.FILTERS.ALL.LOADING'})
              }
              noOptionsMessage={() =>
                intl.formatMessage({id: 'COMPONENTS.ORDERS.LIST.FILTERS.CUSTOMER.NOVALUES'})
              }
              value={
                selectedCompany ? {label: selectedCompany?.name, value: selectedCompany?.id} : null
              }
              getOptionLabel={(w: any) => w.label}
              isClearable={true}
              options={companyState?.items?.map((mp) => ({label: mp.name, value: mp.id})) ?? []}
            />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}>
            <ReactSelect
              isSearchable={false}
              className=' my-1'
              isMulti={true}
              styles={ReactSelectSimpleStyles as StylesConfig}
              onChange={(selected: any) =>
                changeSelectFilter({selected, optionName: statusParameter})
              }
              placeholder={intl.formatMessage({
                id: 'COMPONENTS.ORDERS.LIST.FILTERS.STATUSES.PLACEHOLDER',
              })}
              loadingMessage={() =>
                intl.formatMessage({id: 'COMPONENTS.ORDERS.LIST.FILTERS.ALL.LOADING'})
              }
              noOptionsMessage={() =>
                intl.formatMessage({id: 'COMPONENTS.ORDERS.LIST.FILTERS.STATUSES.NOVALUES'})
              }
              value={
                selectedStatuses?.length
                  ? selectedStatuses.map((ss) => ({label: ss?.name, value: ss?.code}))
                  : null
              }
              getOptionLabel={(w: any) => w.label}
              isClearable={true}
              options={
                orderStatusesState?.items?.map((mp) => ({label: mp.name, value: mp.code})) ?? []
              }
            />
          </Col>
          <Col
            xxl={4}
            xl={4}
            lg={4}
            md={12}
            sm={12}
            xs={12}
            className={`d-flex flex-row align-items-center mt-1`}
          >
            <FormCheckInput
              className={`me-1 my-1 ${css.checkboxCustom}`}
              checked={selectedCreateDatesOn}
              onChange={() =>
                changeDateFilterVisibility(
                  setSelectedCreateDatesOn,
                  selectedCreateDatesOn,
                  [
                    {
                      label: created_after_Parameter,
                      value: selectedCreateDates.startDate.format('YYYY-MM-DD'),
                    },
                    {
                      label: created_before_Parameter,
                      value: selectedCreateDates.endDate.format('YYYY-MM-DD'),
                    },
                  ],
                  [created_after_Parameter, created_before_Parameter]
                )
              }
            />
            <DateRangePickerBootstrap
              className={`w-100 ms-1 text-dark`}
              locale={{
                applyLabel: intl.formatMessage({id: 'ACTION.APPLY'}),
                cancelLabel: intl.formatMessage({id: 'ACTION.CANCEL'}),
              }}
              initDateFrom={selectedCreateDates.startDate.toDate()}
              initDateTo={selectedCreateDates.endDate.toDate()}
              disabled={!selectedCreateDatesOn}
              placeholder={intl.formatMessage({
                id: 'COMPONENTS.ORDERS.LIST.FILTERS.CREATED.PLACEHOLDER',
              })}
              onApply={(event: any, picker: IDateRange) =>
                changeDateFilter(picker, setSelectedCreateDates, [
                  {
                    label: created_after_Parameter,
                    value: picker.startDate.format('YYYY-MM-DD'),
                  },
                  {
                    label: created_before_Parameter,
                    value: picker.endDate.format('YYYY-MM-DD'),
                  },
                ])
              }
            >
              <input
                className='w-100 form-control bg-secondary text-dark'
                value={`${moment(selectedCreateDates.startDate).format('LL')} - ${moment(
                  selectedCreateDates.endDate
                ).format('LL')}`}
                placeholder={intl.formatMessage({
                  id: 'COMPONENTS.ORDERS.LIST.FILTERS.CREATED.PLACEHOLDER',
                })}
              />
            </DateRangePickerBootstrap>
          </Col>
          <Col
            xxl={4}
            xl={4}
            lg={4}
            md={12}
            sm={12}
            xs={12}
            className={`d-flex flex-row align-items-center mt-1`}
          >
            <FormCheckInput
              className={`me-1 my-1 ${css.checkboxCustom}`}
              checked={selectedShipDatesOn}
              onChange={() =>
                changeDateFilterVisibility(
                  setSelectedShipDatesOn,
                  selectedShipDatesOn,
                  [
                    {
                      label: ship_after_Parameter,
                      value: selectedShipDates.startDate.format('YYYY-MM-DD'),
                    },
                    {
                      label: ship_before_Parameter,
                      value: selectedShipDates.endDate.format('YYYY-MM-DD'),
                    },
                  ],
                  [ship_after_Parameter, ship_before_Parameter]
                )
              }
            />
            <DateRangePickerBootstrap
              className={`w-100 ms-1 text-dark`}
              initDateFrom={selectedShipDates.startDate.toDate()}
              initDateTo={selectedShipDates.endDate.toDate()}
              disabled={!selectedShipDatesOn}
              locale={{
                applyLabel: intl.formatMessage({id: 'ACTION.APPLY'}),
                cancelLabel: intl.formatMessage({id: 'ACTION.CANCEL'}),
              }}
              placeholder={intl.formatMessage({
                id: 'COMPONENTS.ORDERS.LIST.FILTERS.SHIPPED.PLACEHOLDER',
              })}
              onApply={(event: any, picker: IDateRange) =>
                changeDateFilter(picker, setSelectedShipDates, [
                  {
                    label: ship_after_Parameter,
                    value: picker.startDate.format('YYYY-MM-DD'),
                  },
                  {
                    label: ship_before_Parameter,
                    value: picker.endDate.format('YYYY-MM-DD'),
                  },
                ])
              }
            >
              <input
                className='w-100 form-control bg-secondary text-dark'
                value={`${moment(selectedShipDates.startDate).format('LL')} - ${moment(
                  selectedShipDates.endDate
                ).format('LL')}`}
                placeholder={intl.formatMessage({
                  id: 'COMPONENTS.ORDERS.LIST.FILTERS.SHIPPED.PLACEHOLDER',
                })}
              />
            </DateRangePickerBootstrap>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col>
            <Filter urlParams={{...(orderState.filter ?? emptyUrlParameters)}} />
          </Col>
        </Row>
        <Row>
          <Col className='d-flex flex-row justify-content-end align-items-center'>
            <Button
              onClick={printBarcode}
              className='my-2 me-2'
              disabled={!selectedIdsItems.length || !isPrintableItems}
            >
              {intl.formatMessage({id: 'COMPONENTS.ORDERS.LIST.BUTTONS.PRINT'})}
            </Button>
            <DropdownButton
              disabled={!selectedIdsItems.length}
              className='my-2'
              as={ButtonGroup}
              title={intl.formatMessage({id: 'COMPONENTS.ORDERS.LIST.BUTTONS.STATUS'})}
              id='bg-nested-dropdown'
            >
              {orderStatusesState?.items
                ?.filter((i) => i.manual_change)
                ?.map((os) => (
                  <Dropdown.Item eventKey={os.id} onClick={() => changeStatuses(os)}>
                    {os.name}
                  </Dropdown.Item>
                ))}
            </DropdownButton>
          </Col>
        </Row>
        <ModalInfo
          title={intl.formatMessage({id: 'COMPONENTS.ORDERS.LIST.MODALS.PRINT.TITLE'})}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      </div>
      <ModalWindowExtended
        applyShow={false}
        cancelShow={false}
        setShow={() => null}
        show={quickSelectedItem.length ? true : false}
        size='xl'
        centered
        headerHide
        bodyClassIsOff
        canApply
      >
        <OrdersItemWrapper id={quickSelectedItem} closeAction={() => setQuickSelectedItem('')} />
      </ModalWindowExtended>
    </OrdersList>
  )
}

export default OrdersListWrapper
