import {takeEvery} from 'redux-saga/effects'
import {sagaUtils} from '../../utils/dataUtils'
import {
  setIsLoading,
  setSubscriptions,
  setIsUserSubscriptionsLoading,
  setUserSubscriptions,
  setIsCreatingPayment,
  setPaymentResponse,
  setIsTopUpBalanceLoading,
  setIsTariffChanging,
  setIsRenewing,
  setSuccesCreatingPayment,
  setInvoiceDocument,
  setRedirectLink,
} from './reducer'
import {
  FETCH_ALL_SUBSCRIPTIONS,
  FETCH_ALL_USER_SUBSCRIPTIONS,
  AUTO_RENEW_SUBSCRIPTION,
  CREATE_PAYMENT,
  TOP_UP_BALANCE,
  BUY_SUBSCRIPTION,
  CHANGE_TARIFF,
  RENEW_SUBSCRIPTION_BY_CARD,
  RENEW_SUBSCRIPTION_BY_BALANCE,
  RENEW_SUBSCRIPTION_BY_INVOICE,
} from './actions'
import {
  getAllSubscriptions,
  getUserSubscriptions,
  autoRenewSubscription,
  createPayment,
  topUpBalance,
  buySubscriptionFromBalance,
  changeTariff,
  renewSubscriptionByCard,
  renewSubscriptionByBalance,
  renewSubscriptionByInvoice,
} from './api'
import {actions} from '../auth/AuthRedux'

export function* subscriptionsSaga() {
  yield takeEvery(FETCH_ALL_SUBSCRIPTIONS, workerGetAllSubscriptions)
  yield takeEvery(FETCH_ALL_USER_SUBSCRIPTIONS, workerGetAllUserSubscriptions)
  yield takeEvery(AUTO_RENEW_SUBSCRIPTION, workerUpdateSubscription)
  yield takeEvery(CREATE_PAYMENT, workerCreatePayment)
  yield takeEvery(TOP_UP_BALANCE, workerTopUpBalance)
  yield takeEvery(BUY_SUBSCRIPTION, workerBuySubscription)
  yield takeEvery(CHANGE_TARIFF, workerChangeTariff)
  yield takeEvery(RENEW_SUBSCRIPTION_BY_CARD, workerRenewSubscriptionByCard)
  yield takeEvery(RENEW_SUBSCRIPTION_BY_BALANCE, workerRenewSubscriptionByBalance)
  yield takeEvery(RENEW_SUBSCRIPTION_BY_INVOICE, workerRenewSubscriptionByInvoice)
}

export function* workerGetAllSubscriptions() {
  yield sagaUtils.workerWrapper(
    setIsLoading,
    getAllSubscriptions,
    null,
    setSubscriptions,
    null,
    'Получение подписок',
    'GET',
    'data.results',
    false
  )
}
export function* workerGetAllUserSubscriptions() {
  yield sagaUtils.workerWrapper(
    setIsUserSubscriptionsLoading,
    getUserSubscriptions,
    null,
    setUserSubscriptions,
    null,
    'Получение активных подписок',
    'GET',
    'data.results',
    false
  )
}

export function* workerUpdateSubscription(action: any) {
  yield sagaUtils.workerWrapper(
    null,
    autoRenewSubscription,
    action.payload,
    null,
    null,
    'Обновление автопродления',
    'SAVE',
    'data',
    true
  )
}
export function* workerTopUpBalance(action: any) {
  yield sagaUtils.workerWrapper(
    setIsTopUpBalanceLoading,
    topUpBalance,
    action.payload,
    setRedirectLink,
    null,
    'Пополнение баланса',
    'SAVE',
    'data.result',
    true,
    '',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    (res: any) => res.confirmation_url
  )
}

export function* workerCreatePayment(action: any) {
  yield sagaUtils.workerWrapper(
    setIsCreatingPayment,
    createPayment,
    action.payload,
    setPaymentResponse,
    null,
    'Создание платежа',
    'CREATE',
    'data.result',
    true,
    '',
    undefined,
    {
      action: setSuccesCreatingPayment,
      params: {success: true},
    }
  )
}
export function* workerBuySubscription(action: any) {
  yield sagaUtils.workerWrapper(
    setIsCreatingPayment,
    buySubscriptionFromBalance,
    action.payload,
    setSuccesCreatingPayment,
    null,
    'Списание средств с баланса',
    'SAVE',
    'data',
    true
  )
}

export function* workerChangeTariff(action: any) {
  yield sagaUtils.workerWrapper(
    setIsTariffChanging,
    changeTariff,
    action.payload,
    null,
    null,
    'Изменение тарифа',
    'SAVE',
    'data.result',
    true,
    '',
    undefined,
    {
      action: actions.setCustomerInfo,
      params: action.payload.updatedCustomerInfo,
    }
  )
}

export function* workerRenewSubscriptionByCard(action: any) {
  yield sagaUtils.workerWrapper(
    setIsRenewing,
    renewSubscriptionByCard,
    action.payload,
    setPaymentResponse,
    null,
    'Продление подписки',
    'CREATE',
    'data.result',
    true
  )
}
export function* workerRenewSubscriptionByBalance(action: any) {
  yield sagaUtils.workerWrapper(
    setIsRenewing,
    renewSubscriptionByBalance,
    action.payload,
    setSuccesCreatingPayment,
    null,
    'Продление подписки',
    'CREATE',
    'data',
    true
  )
}
export function* workerRenewSubscriptionByInvoice(action: any) {
  yield sagaUtils.workerWrapper(
    setIsRenewing,
    renewSubscriptionByInvoice,
    action.payload,
    setInvoiceDocument,
    null,
    'Продление подписки',
    'CREATE',
    'data',
    true,
    '',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    (res: Blob) => {
      const url = window.URL.createObjectURL(res)

      return url
    }
  )
}
