import {useState, ChangeEvent, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import clsx from 'clsx'
import {getYMTokenLinkAction, registerYMTokenAction} from '../../../../../store/mpWizard/actions'

import {Form} from 'react-bootstrap'
import {Button} from 'react-bootstrap'

import {StorageType} from '../../../../../../setup'
import styles from './YMStep1.module.scss'

const YMStep1 = () => {
  const [ymCode, setYmCode] = useState('')

  const userId = useSelector((state: StorageType) => state.auth.user.id)
  const isLoading = useSelector((state: StorageType) => state.mpWizard.isLoading)
  const data = useSelector((state: StorageType) => state.mpWizard.data)
  const ymRedirectLink = data.step0?.ymRedirectLink

  const dispatch = useDispatch()

  useEffect(() => {
    if (ymRedirectLink) {
      window.open(ymRedirectLink, '_blank')
    }
  }, [ymRedirectLink])

  const onGetLink = () => {
    dispatch(getYMTokenLinkAction())
  }

  const onSendCode = () => {
    dispatch(registerYMTokenAction({id: userId, validation_code: ymCode}))
  }

  const onChangeCode = (e: ChangeEvent<HTMLInputElement>) => {
    setYmCode(e.target.value)
  }

  return (
    <div className='fs-5'>
      <p className='mb-8'>
        Для авторизации в аккаунте Яндекс Маркет, пожалуйста, нажмите на кнопку ниже. Это действие
        откроет страницу Яндекс Маркета, где вам нужно будет войти в свой аккаунт, используя ваши
        учетные данные. После успешной авторизации вам необходимо ввести код в поле.
      </p>
      {ymRedirectLink ? (
        <>
          <Form.Control
            name='authorization'
            value={ymCode}
            onChange={onChangeCode}
            className='bg-secondary mb-9'
            placeholder='Введите код'
          />
          <Button disabled={isLoading || !ymCode} className='fs-6' onClick={onSendCode}>
            Отправить код
          </Button>
        </>
      ) : (
        <Button
          variant='outline-primary'
          disabled={isLoading}
          className={clsx('btn-outline', styles.btn)}
          onClick={onGetLink}
        >
          Войти в аккаунт
        </Button>
      )}
    </div>
  )
}

export default YMStep1
