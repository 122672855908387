import axios, {AxiosResponse} from 'axios'
import {
  IFeedback,
  IFeedbackGenerateSettings,
  IFeedbackRequestParams,
  IFeedbackResponse,
  IFeedbackSendParams,
} from './types'
import {IUrlParameters} from '../../components/lists/UrlFilteredList'
import {DEFAULT_PRICES_GET_LIMIT, DEFAULT_PRICES_OFFSET} from '../goodsGroups/constants'

const API_PROD_URL = `${process.env.REACT_APP_API_PROD_URL}${process.env.REACT_APP_ENDPOINT_PREFIX}`

const API_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENDPOINT_PREFIX}`

export const FEEDBACKS_BASE_URL = `${API_URL}v2/feedbacks/`

export const FEEDBACKS_GENERATOR_URL = `${API_URL}v2/feedbacks/generate`

export const FEEDBACKS_GENERATOR_SETTINGS_URL = `${API_URL}v2/feedbacks/add_ai_settings/`

export const FEEDBACKS_GET_GENERATOR_SETTINGS_URL = `${API_URL}v2/feedbacks/get_ai_settings/`

export const FEEDBACK_SEND_URL = `${API_URL}v2/feedbacks/post_feedback_answer`

export const ANSWER_GENERATOR_URL = `${API_PROD_URL}v2/feedbacks/generate`

export const fetchFeedbacks = (params: IUrlParameters) => {
  const offset =
    params.pageNumber !== undefined && params.itemsPerPage !== undefined
      ? params.pageNumber * params.itemsPerPage
      : DEFAULT_PRICES_OFFSET
  const urlSearchParams = new URLSearchParams()
  urlSearchParams.append('limit', (params.itemsPerPage ?? DEFAULT_PRICES_GET_LIMIT).toString())
  urlSearchParams.append('offset', offset.toString())
  urlSearchParams.append('search', params.filter)
  params.additionalParameters?.forEach((param) => {
    urlSearchParams.append(param.label, param.value)
  })
  const results = axios.get<IFeedbackResponse>(
    `${FEEDBACKS_BASE_URL}?${urlSearchParams.toString()}`
  )
  return results
}

export const fetchFeedbackById = (id: number) => {
  return axios.get<IFeedback>(`${FEEDBACKS_BASE_URL}${id}/`)
}

export const generateAnswer = (feedback: IFeedbackRequestParams): Promise<AxiosResponse<any>> => {
  const transformedFeedback: IFeedbackRequestParams = {
    ...feedback,
    text: feedback.text || null,
    category: feedback.category || null,
  }

  return axios.post(ANSWER_GENERATOR_URL, transformedFeedback)
}

export const generateFeedback = (params: IFeedbackRequestParams): Promise<AxiosResponse<any>> => {
  const transformedFeedback: IFeedbackRequestParams = {
    ...params,
    text: params.text || null,
    category: params.category || null,
  }

  return axios.post(FEEDBACKS_GENERATOR_URL, transformedFeedback)
}

export const postFeedback = (params: IFeedbackSendParams): Promise<AxiosResponse<any>> => {
  return axios.post(FEEDBACK_SEND_URL, params)
}

export const postGenerateSettings = (params: IFeedbackGenerateSettings) => {
  return axios.post<IFeedbackGenerateSettings>(FEEDBACKS_GENERATOR_SETTINGS_URL, params)
}

export const fetchFeedbacksSettings = () => {
  return axios.get<IFeedbackGenerateSettings>(FEEDBACKS_GET_GENERATOR_SETTINGS_URL)
}
